body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 스크롤 바 스타일 */
::-webkit-scrollbar {
  width: 1rem; /* 스크롤 바의 너비 */
  
}

::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3); /* 트랙의 배경색 */
  border-radius: 1rem; /* 트랙의 모서리 둥글게 */
}

::-webkit-scrollbar-thumb {
  background: #282c34; /* 스크롤 바의 색상 */
  border-radius: 1rem; /* 스크롤 바의 모서리 둥글게 */
}

::-webkit-scrollbar-thumb:hover {
  background: #0056b3; /* 스크롤 바를 hover 했을 때의 색상 */
}