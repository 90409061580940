@font-face {
  font-family: "GangwonEduHyeonokT_OTFMediumA";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEduHyeonokT_OTFMediumA.woff")
    format("woff");
}
/*폰트 설정예시*/
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="buri"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="buri"]::before {
  content: "Buri";
  font-family: "AritaBuri";
}
.ql-font-buri {
  font-family: "AritaBuri";
}

#toolbar {
  width: 60vw;
  background-color: #c4c4c4;

  @media (max-width: 1200px) {
    width: 100vw;
  }
}

@font-face { 
  font-family: "할아버지의나눔";
  src: url("../../src/assets/ttf/할아버지의나눔.ttf") format("truetype");
} 
.ql-font-할아버지의나눔 {
  font-family: "할아버지의나눔";
}

@font-face { 
  font-family: "끄트머리체";
  src: url("../../src/assets/ttf/끄트머리체.ttf") format("truetype");
}
.ql-font-끄트머리체 {
  font-family: "끄트머리체";
}

@font-face { 
  font-family: "나눔고딕";
  src: url("../../src/assets/ttf/NanumGothic.ttf") format("truetype");
}
.ql-font-나눔고딕 {
  font-family: "나눔고딕";
}

@font-face { 
  font-family: "궁서체";
  src: url("../../src/assets/ttf/ChosunGs.TTF") format("truetype");
}
.ql-font-궁서체 {
  font-family: "궁서체";
}

@font-face { 
  font-family: "굴림체";
  src: url("../../src/assets/ttf/ChosunGu.TTF") format("truetype");
}
.ql-font-굴림체 {
  font-family: "굴림체";
}

@font-face { 
  font-family: "바탕체";
  src: url("../../src/assets/ttf/HCRBatang.ttf") format("truetype");
}
.ql-font-바탕체 {
  font-family: "바탕체";
}

@font-face { 
  font-family: "돋움체";
  src: url("../../src/assets/ttf/HCRDotum.ttf") format("truetype");
}
.ql-font-돋움체 {
  font-family: "돋움체";
}

@font-face { 
  font-family: "Quill";
  src: url("../../src/assets/ttf/Quill.ttf") format("truetype");
}
.ql-font-Quill {
  font-family: "Quill";
}

/* 에디터 양식 */
.ql-editor{ 
  width: 60vw;
  min-height: 60vh;
  img {
    border-radius: 10px !important;
    border: 3px solid #282c34 !important;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); 
  }

  @media (max-width: 1200px) {
    width: 100vw;
  }
}

.ql-syntax {
  background-color: white !important;
  color: black !important;
  border-radius: 10px !important;
  border: 3px solid black !important;
}